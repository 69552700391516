@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;1,300&display=swap');

.home{
    overflow: hidden;
    font-family: 'Rubik';
    display: flex;
    flex-direction: column;   
}
.rob {
    position: fixed;
    bottom: 50vh;
    right: 0;
    opacity: 0;
    z-index: 888888888;
    height: 5vh;
    border: none;
    background-color: rgb(220, 235, 7);
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  
  .rob.visible {
    opacity: 1;
    visibility: visible;
  }
.intro-images .to{
    height: 60vh;
}
.intro-top p::first-letter{
    font-size: 2em;
}
.header{
    display: flex;
    flex-direction: row;
    height: 10vh;
    width: 100vw;
    color: white;
    z-index: 55555555555;
    position: relative;
}
.icon{
    width: 38%;
    display: block;
    margin-top: -5vh;
    margin-left: 2.5vw;
    
}
.barn{
    color: goldenrod;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    text-align: center;
}
.barn img{
    width: 10%;
}
.aboutimg{
    background-size: cover;
}
.header:hover{
    background-color: white;
}
.header:hover h2{
    color: black;
}
.left-top h2{
    margin-left: 10vh;
    width: 100%;
}
.right-top{
    display: inline-flex;
    flex-direction: row;
    margin-left: 25vw;
    width: 80vw;
}
.right-top a{
   
    color: white;
    text-decoration: none;
}
.header:hover a{
    color: black;
}
.button1{
    width: 200px;
    height: 50px;
    margin-top: 30px;
    color: white;
    background-color: rgb(10, 104, 245);
    border: none;
    font-size: 17.5px;
    align-items: center;
    text-align: center;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
}
.button2{
    width: 200px;
    height: 50px;
    color: white;
    background-color: rgb(10, 104, 245);
    border: none;
    margin-top: 15px;
    font-size: 17.5px;
    align-items: center;
    text-align: center;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
}
.button2:hover{
    color: rgb(10, 104, 245);
    background-color: white;
    border: 1px solid rgb(10, 104, 245);
}
.button1:hover{
    background-color: white;
    color:rgb(10, 104, 245);
}
.button1 i{
    font-size: 20px;
    margin-top: 2.5%;
}
.loss{
    width: 15vw;
    height: 6vh;
    cursor: pointer;
    color: white;
    background-color: rgb(10, 104, 245);
    margin-left: 42.5vw;
    font-size: 20px;
    border: none;
}
.loss:hover{
    color: rgb(10, 104, 245);
    background: white;
}

.right-top a{
    margin-left: 4.5vw;
    font-size: 14.5px;
    font-weight: 350;
    cursor: pointer;
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 30px; 
}
.right-top a:hover{
    color: rgb(10, 104, 245);
    border-top: 5px solid rgb(10, 104, 245)
}
.landing{
    overflow: hidden;    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    color: white;
    text-align: center;
    align-items: center;
    position: relative;
    animation-name: slideAnimation;
    animation-duration: 14s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    top: 0;
    left: 0;
    
}
.landing::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    
  }

@keyframes slideAnimation {
    0%, 100%{
        background-image:  url("./Images/pish26.jpg");
    }
    25%{
        background-image:  url("./Images/pish31.jpg");
    }
    50%{
        background-image:  url("./Images/pish25.jpg");
    }
    75%{
        background-image:  url("./Images/pish28.jpg");
    }
}
.land-cont{
    display: block;
    margin-left: 25vw;
}
.land-cont h1{
    font-size: 45px;
    font-weight: 100;
}
.land-cont p{
    width: 50vw;
    margin-left: 0vw;
    text-align: center;
    font-size: 18px;
    font-weight: 150;
}
.land-cont{
    margin-top: 20vh;
    position: absolute;
    bottom: -100%;
    height: 75vh;
    animation-name: slideFromBottom;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}
@keyframes slideFromBottom {
    0%{
        bottom: -100%;
    }
    100%{
        bottom: 0;
    }
}
.blue{
    background-color: rgb(10, 104, 245);
    color: white;
    height: fit-content;
    text-align: center;
    align-items: center; 
    overflow: hidden;
    width: 100%;
    
}
.blue p{
    width: 60vw;
    font-size: 30px;
    font-weight: 150;
    text-align: center;
    align-items: center;
    margin-left: 20vw;
    margin-top: 7.5vh;
    line-height: 2.0;
}
.chand  {
    text-align: center;
    margin-top: 5vh;
}
.chand a {
    margin: 5vh;
    font-size: 25px;
    cursor: pointer;
    color: white;
}
.chand i:hover{
    color: rgb(10, 104, 245)
}
.about-sec{
    height: fit-content;
    background-color: aliceblue;
}
.loss{
    width: 5vw;
    margin-left: 47.5vw;
}
.about-head{
    text-align: center;
    font-size: 40px;
}
.about{
    display: flex;
    flex-direction: row;   
}
.about1{
    width: 50%;
}
.about1 p{
    width: 80%;
    margin-left: 5vw;
    line-height: 1.5;
    color: grey;
}
.about2{
    overflow: hidden;    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 50%;
    color: white;
    text-align: center;
    align-items: center;
    position: relative;
    animation-name: slideAnimation2;
    animation-duration: 12s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    top: 0;
    left: 0;
}
@keyframes slideAnimation2 {
    0%, 100%{
        background-image:  url("./Images/bg1.jpg");
    }
    25%{
        background-image:  url("./Images/bg2.jpg");
    }
    50%{
        background-image:  url("./Images/bg3.jpg");
    }
    75%{
        background-image:  url("./Images/pish28.jpg");
    }
}
.misvis{
    display: flex;
    flex-direction: row;
    background-color: rgba(233, 231, 231, 0.733);
    height: 75vh;
}
.mission{
    width: 50vw;
}
.vision{
    width: 50vw;
}
.mis-img{
    background-image: linear-gradient(rgba(89, 124, 238, 0.466), rgba(89, 124, 238, 0.466)), url("./Images/pish33.jpg");
    height: 45vh;
    background-size: cover;
    background-position: center;
    width: 40vw;
    margin-left: 5vw;
    position: absolute;
    margin-top: 10vh;
}
.vis-img{
    height: 45vh;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(rgba(89, 124, 238, 0.466), rgba(89, 124, 238, 0.466)), url("./Images/pish10.jpg");
    width: 40vw;
    margin-left: 5vw;
    position: absolute;
    margin-top: 10vh;
}
.vis-text{
    text-align: center;
    width: 75%; 
    background-color: white;
    margin-left: 12.5%;
    margin-top: 30%;
    height: 30vh;   
}
.vis-text p{
    padding-left: 5%;
    padding-right: 5%;
}
.mis-text p{
    padding-left: 5%;
    padding-right: 5%;
}

.mis-text{
    text-align: center;
    width: 75%; 
    background-color: white;
    margin-left: 12.5%;
    margin-top: 30%;
    height: 30vh;   
}
.services{
    position: relative;
    height: 100vh;
    margin-top: 5vh;
}
.serv-info{
    display: flex;
    flex-direction: row;
    margin-top: 10vh;
}
.services h1{
    text-align: center;
}
.serv-details{
    width: 17.5vw;
    border: 1px solid rgb(187, 180, 180);
    margin-left: 3.75vh;
    height: 65vh;
    flex-direction: column;
    cursor: pointer;
}
.cole{
    width: 100%;
    height: 40%;
}
.con{
    height: 60%;
    margin-top: -20px;
}
.con h3{
    text-align: center;
    margin-top: 7%;
}
.con p{
    font-size: 13.5px;
    padding: 2.5%;
}
.serv-details:hover{
    box-shadow: -0px 12px 20px rgba(0, 0, 0, 0.3);
    border: 1.5px solid rgb(1, 1, 37);
}

.serv-details:hover .con{
    color: white;
    background-color: rgb(1, 1, 37);
}
.happy, .happy2, .happy3, .happy4, .happy5{
    display: flex;
    flex-direction: row;
    padding-left: 10vw;
    padding-right: 10vw;
}
.portfolio{
    background-color: rgb(1, 1, 37);
    color: white;
}
.portfolio img{
    width: 35%;;
    height: 40vh;
    padding: 5vh;
}
.happy2, .happy4{
    flex-direction: row-reverse;
}
.thor{
    text-align: center;
}
.portfolio h1{
    text-align: center;
}
.loki, .loki2, .loki3, .loki4, .loki5{
    width: 40vw;
    margin-top: 15vh;
    font-size: 18px;
    border: 2px solid white;
    height: 15vh;
    padding: 20px;
    
}
.loki3{
    border-left: 10px solid yellow;
}
.loki4{
    border-right: 10px solid rgb(235, 7, 102);
}

.loki{
    border-left: 10px solid red;
}
.loki2{
    border-right: 10px solid rgb(2, 71, 2);
}
.loki5{
    border-left: 10px solid rgb(10, 104, 245);
}
.footer{
    height: 80vh;
    color: white;
    background-color: black;
}
.intro-images{
    margin-top: 10vh;
    justify-content: center;
    align-items: center;
    margin-left: 3vw;
}
.intro-images img{
    width: 30vw;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin: 5px;
}
.ross{
    display: flex;
    flex-direction: row;
}
.ross .mon{
    width: 40vw;
    margin-left: 10vw;
    text-align: left;
    line-height: 2;
}
.footer h1{
   text-align: center;
   font-weight: 400;
}
.intro-images h1{
    text-align: center;
    font-weight: 400;
}
.joe{
    margin-left: 5vw;
    line-height: 2;
}
.intro{
    background-color: aliceblue;
}
.intro-top{
   border: 1px solid black;
   font-family: 'Rubik';
   margin-top: 2.5vh;
   background-color: white;
   box-shadow: -0px 12px 20px rgba(0, 0, 0, 0.3);
   width: 80vw;
   margin-left: 10vw;
   border-radius: 10px;
   border-top: 10px solid rgb(10, 104, 245);
}
.intro-top p{
    width: 70vw;
    margin-left: 7.5%;
    line-height: 2.0;
}
.back{
    margin-top: -50px;
    margin-left: 1.5%;
    height: 30px;
    cursor: pointer;
    color: white;
    background-color: rgb(1, 1, 37);
    border: none;
}
.back:hover{
    color: rgb(1, 1, 37);
    background: white;
    border: 1px solid rgb(1, 1, 37);
}
.header-toggle{
    display: none;
}
@media screen and (max-width: 1024px) {
    .landing{
        height: 100vh;
    }
    
    .land-cont{
        height: 90vh;
    }
    .icon{
        width: 20%;
        position: absolute;
        margin-left: 0vw;
        margin-top: -2.5vh;
    }
    .right-top{
        display: none;
    }
    .right-top.active{
        position: relative;
        display: block;
        flex-direction: column;
        margin-left: 10vw;
        margin-top: 15vh;
        z-index: 13224444444;
        background-color: black;
        border-radius: 10px;
        height: 60vh;
        color: black;
        box-shadow: 0px 0px 20px black;
    }
    .header:hover h2{
        background: transparent;
    }
    .left-top:hover h2{
        color:initial;
    }
    .header-toggle{
        display: block;
        position: absolute;
        font-size: 60px;
        color: white;
        margin-top: 5vh;
        margin-left: 90vw;
        background: none;
        border: none;
        font-size: 35px;
    }
    .right-top a{
        color: white;
        margin-left: -2.5%;
    }
    .right-top h2{
        margin-top: 6vh;
    }
    .right-top.active a:hover{
        color: blue;
        border-top: none;
    }
    .header:hover{
        background-color: initial;
    }
    .header:hover h2{
        background-color: initial;
    }
    .header:hover a{
        color:white;
    }
    
    .blue{
        height: 100vh;
    }
    .about-sec{
        height: fit-content;
    }
    .services{
        margin-top: 10vh;
        height: fit-content;
    }
    .about{
        flex-direction: column;
        width: 100vw;
        height: 130vw;
    }
    .about1{
        margin-left: 5vw;
        width: 100vw;
    }
    .about2{
        height: 50vh;
        width: 85vw;
        margin-left: 6.5vw;
    }
    .misvis{
        height: 55vh;
    }
    .mis-img, .vis-img{
        height: 25vh;
    }
    .mis-text, .vis-text{
        width: 90%;
        margin-left: 15px;
        margin-top: 35%;
        height: 20vh;
    }
    .mis-text p, .vis-text p {
        font-size: 12px;
    }
    .intro-images img{
        height: 25vh;
        margin-left: 0px;
    }
    .services{
        height: 170vh;
    }
    .serv-info{
        flex-direction: column;
    }
    .serv-details{
        display: flex;
        flex-direction: row;
        width: 90vw;
        margin-bottom:25px;
        height: 25vh;
    }
    .cole{
        width: 40%;
        height: 25vh;
    }
    .serv-details:hover .con{
        color: initial;
        background-color: initial;
    }
    .con h3{
        text-align: left;
        margin-left: 10px;
    }
    .happy, .happy2, .happy3, .happy4, .happy5{
        width: 100vw;
        margin-left: -10vw;
    }
    .portfolio img{
        height: 30vh;
    }
    .loki, .loki2, .loki3, .loki4, .loki5{
        font-size: 15px;
        margin-top: 7.5vh;
    }
    
    .intro-top{
        height: fit-content;
    }
    .intro-top p{
        margin-top: 2.5vh;
        font-size: 21px;
    }
    .blue{
        height: fit-content;    }
    .blue p{
        margin-top: 7.5vh;
    }
    .about1 p {
        font-size: 25px;
    }
    .about-sec{
        height: 150vh;
    }
    .mis-img, .vis-img{
        height: 15vh;
        width: 47.5vw;
        margin-left: 10px;
    }
    .mis-text, .vis-text{
        margin-top: 135px;
        height: 15vh;
        font-size: 25px;
        margin-left: 22.5px;
    }
    .mis-text p, .vis-text p{
        font-size: 18.5px;
    }
    .misvis{
        height: 45vh;
    }
    .about-head{
        font-size: 45px;
    }
    .services h1{
        font-size: 55px;
    }
    .serv-details{
        height: 25vh;
        margin-left: 35px;
    }
    .con h3{
        font-size: 35px;
    }
    .con p{
        font-size: 24.5px;
        margin-top: -3.5vh;
    }
    .portfolio h1{
        font-size: 55px;
    }
    .thor{
        width: 80vw;
        margin-left: 10vw;
    }
    .happy, .happy2, .happy3, .happy4, .happy5{
        flex-direction: column;
        width: 100vw;
        margin-left: -2.5vw;
    }
    .thor{
        font-size: 28px;
        margin-left: 6.25vh;
    }
    .ross{
        font-size: 25px;
        line-height: 2;
    }
    .footer1 h1{
        font-size: 50px;
        text-align: center;
        margin-left: -5vw;
    }
    .loss{
        margin-top: -0vh;
        
    }
    .port p{
        width: 65vw;
        margin-top: -5vh;
        margin-left: 6.5vw;
        font-size: 28px;
    }
    .port img{
        width: 70vw;
        height: 30vh;
    }
    
    .land-cont h1{
        font-size: 60px;
        text-align: center;
        margin-left: -20vw;
    }
        .button1{
        display: block;
        margin-left: 10vw;
        width: 300px; height: 7.5vh;
        font-size: 30px;
    }
    .land-cont a {
        text-decoration: none;
    }
    .right-top.active a{
        font-size: 35px;
    }
    .land-cont p{
        font-size: 25px;
        width: 60vw;
        margin-left: -5vw;
    }
    
}
@media screen and (max-width: 820px) {
    .header-toggle{
        margin-left: 85vw;
        margin-top: 2vh;
        color: white;
        font-size: 45px;
    }
    .land-cont h1{
        font-size: 60px;
       text-align: center;
    }

    .button1{
        margin-left: 6.25vw;
    }
    .blue{
       height: 90vh; 
    }
    .blue p{
        font-size: 45px;
    }
    .about-sec{
        height: fit-content;
    }
    .about{
        height: fit-content;
    }
    .mis-text, .vis-text{
        height: 17vh;
    }
    .serv-details p{
        font-size: 22.5px;
    }
    .intro-images img{
        width: 45%;
        margin-left: 2vw;
    }
    .ross .mon{
        width: 80vw;
    }
    .thor {
        font-size: 35px;
    }
   .port p{
        font-size: 22.5px;
        width: 50vw;
        height: 19.5vh;
    }
    .right-top.active{
        height: 50vh;
        width: 60vw;
        margin-left: 20vw;
        margin-top: 10vh;
    }
    .blue{
        height: fit-content;
    }
    .port p{
        width: 65vw;
        font-size: 22.5px;
        height: 15vh;
    }
    .ross{
        flex-direction: column;
        width: 100vw;
    }
    .mon{
        width: 100vw;
        margin-left: -5vw;
        text-align: left;
    }
    .joe{
        margin-left: 10vw;
    }
    .intro-top{
        height: fit-content;
    }
    }
    @media screen and (max-width: 768px) {
        .intro-top p{
            font-size: 18px;
        }
        .land-cont h1{
            margin-left: -25vw;
        }
        .land-cont p{
            margin-left: -5vw;
        }
        .intro-top{
            height: 80vh;
        }
        .blue p{
            font-size: 35px;
            width: 90vw;
            margin-left: 5vw;
        }
        .mis-text, .vis-text{
            margin-top: 25%;
            margin-left: 17.5px;
            height: 20vh;

        }
        .serv-details p{
            font-size: 17.5px;
            margin-top: -49.5px;
        }
        .port p{
            border-left: 1px solid;
            border-right: 1px solid;
            width: 64.7vw;
            border-top: none;
        }
        .button1{
            margin-left: 5vw;
        }

    }
    @media screen and (max-width: 430px) {
        .land-cont h1{
            z-index: 10000;}
        .barn img {
            width: 40%
        }
        .landing{
            overflow: hidden;    
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1111111111;
            background-size: cover;
            height: 100vh;
            color: white;
            text-align: center;
            align-items: center;
            position: relative;
            animation-name: slideAnimation;
            animation-duration: 14s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            top: 0;
            left: 0;
        }
        @keyframes slideAnimation {
            0%, 100%{
                background-image:  url("./Images/pish26.1.jpg");
            }
            25%{
                background-image:  url("./Images/pish31.1.jpg");
            }
            50%{
                background-image:  url("./Images/pish25.1.jpg");
            }
            75%{
                background-image:  url("./Images/pish28.1.jpg");
            }
        }
        .landing::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            
          }
        
        .home{
            display: flex;
            flex-direction: column;
        }
        .icon{
            width: 30%;
        }
        .header-toggle{
            margin-left: 85vw;
            font-size: 35px;
            }
            .right-top.active{
                width: 80vw;
                margin-top: 10vh;
                height: 42.5vh;
                margin-left: 10vw;
            } 
            .intro-top{
                height: fit-content
            }
            .intro-top p{
                line-height: 1.5;
            }
            .intro-images img{
                width: 92.5%;
            }
        .right-top.active a{
            font-size: 25px;
        }
        .landing{
            height: 100vh
        
        } 
        .land-cont{
            height: 85vh;
        }
        .land-cont h1{
            font-size: 40px;
            width: 100vw;
            margin-left: -25vw;
        }
        .land-cont p{
            font-size: 20.5px;
            margin-left: -15vw;
            width: 80vw;
        }
        .button1{
            margin-left: 5vw;
            width: 40vw;
            font-size: 20px;
        }
        .about2{
            height: 35vh;
         }
    
        .blue{
            display: block;
            height: fit-content;
            text-align: center;
        }
        .blue p{
            font-size: 25px;
        }

        .about-sec{
            height: fit-content;        }
        .about1 p{
            font-size: 18.5px;
            width: 90vw;
            margin-left: 0vw;
        }
        .about2{
            width: 100vw;
            margin-left: 0vw;

        }
        .misvis{
            flex-direction:column;
            width: 100vw;
            height: fit-content;
        }
        .mission, .vision{
            height: 55vh;
            width: 100vw;
            margin-left: 2.5vw;
        }
        .mis-img, .vis-img{
            width: 90vw;
            height: 25vh;
        }
        .mis-text, .vis-text{
            margin-top: 30%;
            height: fit-content;
        }
        .services{
            height: fit-content;
        }
        .services h1{
            font-size: 40px;
        }
        .serv-details{
            margin-left: 5vw;
            height: fit-content;
            flex-direction: column;
        }
        .con h3{
            font-size: 25px;
            margin-top: 20px;
            text-align: center;
        }
        .con p{
            margin-top: 0px;
        }

        .cole{
            width: 100%;
            height: 40%;
        }
        .portfolio h1{
            font-size: 45px;
        }
        .thor{
            font-size: 25px;
            margin-left: 7.5vw;
        }
        .portfolio img{
            width: 90vw;
            margin-left: -15vw;
        }
        .port p{
            width: 80vw;
            margin-left: -4.25vw;
            border: none;
            font-size: 18px;
        }
        .footer{
            height: 120vh;
        }
        .ross .mon{
            width: 90vw;
            margin-left: 2.5vw;
            font-size: 20px;
        }
        .ross .joe{
            margin-left: 2.5vw;
            font-size: 20px;
            font-size: 20px;
        }
        .loss{
            width: 100px;
            margin-left: 40vw;
            margin-top: 15vh;
        }
        .happy, .happy2, .happy3, .happy4, .happy5{
            border: 1px solid white;
            margin-left: -0.5vw;
            
        }
        .footer{
            height: fit-content;
        }
        .intro-images img{
            height: 40vh;
        }
    }
@media screen and (max-width: 414px) {
    .blue{
        height: fit-content;
    }
    .blue p{
        width: 80vw;
        margin-left: 10vw;
    }
    .mis-text, .vis-text{
        height: 22.5vh;
    }
    .serv-details{
        height: 52vh;
    }
    .loss{
        margin-top: 10vh;
    }
}
@media screen and (max-width: 414px) and (max-height: 823px){
    .blue{
        height: fit-content;
        
    }
    .blue p{
        width: 90vw;
        margin-left: 5vw;
    }
    
    .about-sec{
        height: fit-content;
    }
    .about{
        height: fit-content;
    }
    .misvis{
        height: 120vh;
    }
    .mis-text, .vis-text{
        height: 30vh;
        margin-top: 30%;
    }
    .vision{
        margin-top: 10vh;
    }
    .services{
        height: 330vh;
    }
    .serv-details{
        height: 58vh;
    }
    .con p{
        margin-top: -20px;
    }
    .footer{
        height: fit-content;
    }
}
@media screen and (max-width: 393px) {
    
    .about-sec{
        height: fit-content;
    }
    .vision{
        margin-top: 5vh;
    }
    .mis-text, .vis-text{
        height: 25vh;
    }
    .serv-details{
        height: 58vh
    }
    .services{
        height: 330vh;
    }
    .port p{
        height: 20vh;
        width: 79vw;
    }
    .ross .joe{
        font-size: 20px;
    }
    .intro-top{
        width: 90vw;
        height: 130vh;
        margin-left: 2.5vw;
    }
    .intro-top p{
    line-height: 1.5;
    width: 90%;
   }
   .intro-images{
    width: 90vw;
   }
   .intro-images img{
    width: 100%;
   }
   .button1{
    width: 42.5vw;
   }
   
}
@media screen and (width: 393px) and (height: 786px){
    .intro-top{
    height: 140vh;       
    }
    .about-sec{
        height: 320vh;
    }
    .misvis{
        height: 90vh;
    }
    .mis-text, .vis-text{
        height: 30vh;
    }
    
}
@media screen and (max-width: 384px) {
    .button1{
        margin-left: 2.5vw;
        width: 45vw;
    }
    .misvis{
        height: fit-content;
    }
    .loss{
        margin-left: 37.5vw;
    }
    .misvis{
        height: 100vh;
    }
    .mis-text, .vis-text{
        height: fit-content;
    }
    .intro-top{
        height: fit-content;
        margin-left: 5vw;
    }
}
.right-top.active{
    height: 45vh;
}


@media screen and (max-width: 384px) and (height: 640px){
    .intro-top{
        height: 180vh;
    }
    .intro-images img{
        height: 35vh;
    }
    .land-cont h1{
        font-size: 38px;
        font-weight: 600;
    }
    .blue{
        height: 110vh;
    }
    .about-sec{
        height: 330vh;
    }
    .vision{
        margin-top: 15vh;
    }
    .mis-text, .vis-text{
        height: 35vh;
    }
    .serv-details{
        height: 65vh;
    }
    .services{
        height: 370vh;
    }
     }
    @media screen and (max-width: 375px) {
     .about-sec{
        height: fit-content;
     }   
     .about2{
        height: 35vh;
     }
     .mis-text, .vis-text{
        height: fit-content;
        margin-top: 10vh;
    }
    .land-cont{
        height: 90vh;
    }
    .blue{
        height: fit-content;
    }
    .intro-top{
        height: fit-content;
    }
    .right-top.active{
        height: 50vh;
    }
    .serv-details{
        height: fit-content;
    }
    .intro-top{
        margin-left: 5vw;
    }
    .services{
        height: fit-content;
    }
    .port p{
        margin-left: -6vw;
        width: 78vw;
        height: fit-content;
    }
    .about{
        height: fit-content;
    }
    }
    @media screen and (max-width: 1085px) and (min-width: 375px) and (max-height: 640px) and (min-height: 635px) {
        .land-cont p{
            font-size: 20px;
            margin-left: -2.5vw;
        }
        .right-top.active{
            height: 60vh;
        }
       .about-sec{
        height: fit-content;
        display: flex;
        flex-direction: column;  
       }
       .about{
        display: flex;
        height: fit-content;
        flex-direction: column;
       }
     .footer{
        height: fit-content;
     }  
       .mis-text, .vis-text{
        height: fit-content;
       }
       .serv-details{
        height: fit-content;
       }
       .serv-details p{
        font-size: 12.5px;
        margin-top: -4.5vh;
       }
       .serv-details h3{
        font-size: 20px;
       }
       .port img{
        margin-left: 2.5vw;
        height: 40vh;
       }
       .port p{
        border: none;
       }
       .happy, .happy2, .happy3, .happy4, .happy5{
        margin-top: 15vh;
        border-top: 1px solid white;
}
.intro-top{
    height: fit-content;
}
}
@media screen and (max-width: 1024px) and (min-width: 480px)and (max-height: 768px){
    .about-sec{
        height: fit-content;
    }
    .about{
        height: fit-content;
    }
    .vis-img, .mis-img{
        height: 30vh;
    }
    .vis-text, .mis-text{
        height: fit-content;
    }
    .misvis{
        height: 50vh;
    } 
    .serv-details{
        height: fit-content;
    }
    .cole{
        width: 30%;
        height:35vh;
    }
    .about2{
        height: 90vh;
    }
    .con p{
        font-size: 20px;
    }
    .services{
        height: fit-content;
    }
    .port img{
        height: 50vh;
        margin-left: 2.5vw;
    }
    .port p{
        height: min-content;
    }   
}
@media screen and (max-width: 854px) and (max-height: 375px) and (min-width: 800px){
    .land-cont{
        margin-left: 20vw;
    }
    .land-cont p{
        margin-left: -2.5vw;
    }
    .con {
        margin-top: -30px;
    }
    .about2{
        height: 90vh;
    }
    
}
@media screen and (width: 533px) and (height: 320px){
    .land-cont {
        height: 100vh;
    }
    .land-cont p{
        font-size: 18px;
    }
    .button1{
        margin-right: 5vw;
        margin-top: -5vh;
        width: 40vw;
        font-size: 20px;
    }
    .con h3{
        font-size: 25px;
        margin-bottom: 50px;
    }
    .about2{
        height: 90vh;
    }
   
}
@media screen and (max-width: 950px) and (min-height: 320px) and (max-height: 430px){
    .misvis{
        height: 100vh;
    }
    .mis-img, .vis-img{
        height: 50vh;
    }
    .cole{
        height: 50vh;
    }
    .serv-details{
        height: 55vh;
    }
    .con p{
        margin-top: -50px;
        font-size: 17px;
    }
    .about2{
        height: 90vh;
    }
    .thor{
        margin-left: 10vw;
    }
    .port img{
        height: 70vh;
        margin-left: 5vw;
    }
    .footer{
        height: fit-content;
    }
    .ross{
        display: flex;
        flex-direction: column;
    }
    .ross .mon{
        width: 80vw;
        text-align: left;
    }
    .land-cont h1{
        font-size: 40px;
        margin-top: 20vh;
        margin-left: -7.5vw;
    
    }
    .button1{
        height: 15vh;
        margin-left: 8.75vw;
    }
    .right-top.active{
        height: 75vh;
    }
    .blue p{
        width: 80vw;
        font-size: 35px;
        margin-left: 10vw;
    }
    .intro-top{
        height: fit-content;
    }
    .intro-images img{
        height: 50vh;
        width: 40vw;
        margin-left: 5vw;
    }
}
@media screen and (max-width: 740px) and (min-height: 320px) and (max-height: 430px){
    .port p{
        border: none;
    }
    .happy, .happy2, .happy3,.happy4,.happy5{
        border: 1px solid white;       
    }
    .land-cont h1{
        margin-left: -15vw;
    }
    .button1{
        margin-left: 5vw;
    }
    .con p{
        font-size: 15px;
    }
}
@media screen and (max-width: 670px) and (min-height: 320px) and (max-height: 430px) {
    .land-cont h1{
        width: 100vw;
        margin-left: -25vw;
    }
    .land-cont p{
        width: 80vw;
        margin-left: -15vw;
    }
    .button1{
        margin-left: 2.5vw;
    }
    .con p{
        font-size: 12.5px;
    }
}
@media screen and (max-width: 653px) and (height: 280px) {
    .landing{
        height: 200vh;
    }
    .land-cont{
        height: 180vh;
    }
    .button1{
        height: 20vh;
        margin-left: 2.5vw;
    }
    .about-sec{
        height: fit-content;
    }
    .misvis{
        height: 100vh;
    }
    .port p{
        border: none;
    }
    .footer{
        height: fit-content;
    }
    
}
@media screen and (max-width: 360px) and (min-height: 640px){
    .button1{
        width: 47.5vw;
        margin-left: 0vw;
    }
}
@media screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
       .landing{
        background-image: url('./Images/meeting.png');
       }    
}